import React from "react"
import { graphql } from "gatsby"

import { GatsbyPage } from "src/types"
import Layout from "src/layout"
import Image from "src/components/Image"
import { Section, Subtitle, Title, Description, SectionTitle, Text, Heading, Content } from "src/components/styled"
import Gallery from "src/components/Gallery"
import { Splash, Parallax } from "src/components/Parallax"
import { scrollToRef } from "src/helpers/window"

const InfosPage: GatsbyPage = ({ data }) => {
  return (
    <Layout title="Infos">
      <Splash>
        <Parallax desktop={data.splashDesktop.childImageSharp} mobile={data.splashMobile.childImageSharp} />
      </Splash>
      <Content>
        <Section>
          <Subtitle>Plus d’informations</Subtitle>
          <Title ref={scrollToRef}>
            <span>Un espace conçu pour favoriser votre autonomie pendant l’atelier</span>
          </Title>
          <Description>
            Les Ateliers Chutes Libres sont adaptés pour recevoir un public débutant ou confirmé, en individuel ou en
            groupe. Comme il s’agit de réemploi, les meubles sont fabriqués avec la contrainte d’utiliser le bois
            disponible sur place. L’accès aux machines et la manipulation des outils se fait avec l’accompagnement des
            encadrant·e·s.
          </Description>
        </Section>
        <Section>
          <SectionTitle>L’espace de l’atelier</SectionTitle>
          <Text>
            Les Ateliers Chutes Libres sont installés sous une voute du Viaduc des Arts dans un espace d’atelier de plus
            150 m<sup>2</sup>. L’atelier est organisé sur deux niveaux. Le rez-de-chaussée est aménagé pour les temps de
            conception et d’assemblage des objets, tandis qu’au sous-sol, une salle des machines est dédiée à la découpe
            et au ponçage du bois.
          </Text>
        </Section>
        <Section>
          <Gallery columns={2}>
            <figure>
              <Image file={data.workshop1.childImageSharp} />
              <figcaption>Le Rez-de-chausée de l’atelier</figcaption>
            </figure>
            <figure>
              <Image file={data.workshop2.childImageSharp} />
              <figcaption>La vitrine de l’atelier</figcaption>
            </figure>
            <figure>
              <Image file={data.workshop3.childImageSharp} />
              <figcaption>Nos caisses de rangement</figcaption>
            </figure>
          </Gallery>
        </Section>
        <Section>
          <SectionTitle>Les chutes de bois</SectionTitle>
          <Text>
            Le bois que nous collectons auprès des musées et des artisan·e·s parisien·ne·s est soigneusement classé en
            fonction des tailles de chutes et mis à la disposition des participant·e·s (dans la limite autorisée par
            chaque format d’atelier). Le but étant de valoriser le bois collecté, nous ne pouvons pas garantir la
            présence d’un type de bois spécifique lors de votre venue. Cependant nous faisons tout pour vous proposer un
            large choix de chutes et une quantité suffisante pour mener à bien vos projets.
          </Text>
        </Section>
        <Section>
          <Gallery columns={2}>
            <figure>
              <Image file={data.wood1.childImageSharp} />
              <figcaption>Tasseaux</figcaption>
            </figure>
            <figure>
              <Image file={data.wood2.childImageSharp} />
              <figcaption>Petites chutes</figcaption>
            </figure>
            <figure>
              <Image file={data.wood3.childImageSharp} />
              <figcaption>Lattes</figcaption>
            </figure>
            <figure>
              <Image file={data.wood4.childImageSharp} />
              <figcaption>Matériauthèque</figcaption>
            </figure>
          </Gallery>
        </Section>
        <Section>
          <SectionTitle>OUTILS ET MACHINES</SectionTitle>
          <Text>
            Notre priorité dans l’usage des machines et des outils est le respect des règles de sécurité. Les
            participant·e·s sont accompagné·e·s dans leur usage des outils pour qu’il·elle·s acquièrent les bonnes
            pratiques afin de pouvoir réaliser leur meuble par eux·elles-même en toute sécurité.
          </Text>
          <Text>
            <Heading>Liste des machines disponibles à l’atelier :</Heading>
            <br />
            Perceuse/visseuse • Perceuse à colonne • Scie sauteuse • Scie à onglet pendulaire • Scie circulaire
            plongeante sur rail • Scie circulaire sur table • Défonceuse (usage réservé aux ateliers individuels) •
            Clouteuse pneumatique • Ponceuse électrique • Consommables de base (visseries et clous)
          </Text>
        </Section>
        <Section>
          <Gallery columns={2}>
            <figure>
              <Image file={data.tool4.childImageSharp} />
              <figcaption>Scie à onglet pendulaire</figcaption>
            </figure>
            <figure>
              <Image file={data.tool2.childImageSharp} />
              <figcaption>Scie sauteuse</figcaption>
            </figure>
            <figure>
              <Image file={data.tool1.childImageSharp} />
              <figcaption>Perceuse visseuse</figcaption>
            </figure>
            <figure>
              <Image file={data.tool3.childImageSharp} />
              <figcaption>Ponceuse rotative</figcaption>
            </figure>
          </Gallery>
        </Section>
      </Content>
    </Layout>
  )
}

export default InfosPage

export const query = graphql`
  query {
    splashDesktop: file(relativePath: { eq: "infos/splash-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    splashMobile: file(relativePath: { eq: "infos/splash-mobile.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    workshop1: file(relativePath: { eq: "infos/atelier1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    workshop2: file(relativePath: { eq: "infos/atelier2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    workshop3: file(relativePath: { eq: "infos/atelier3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wood1: file(relativePath: { eq: "infos/chutes1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wood2: file(relativePath: { eq: "infos/chutes2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wood3: file(relativePath: { eq: "infos/chutes3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wood4: file(relativePath: { eq: "infos/chutes4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tool1: file(relativePath: { eq: "infos/machines1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tool2: file(relativePath: { eq: "infos/machines2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tool3: file(relativePath: { eq: "infos/machines3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tool4: file(relativePath: { eq: "infos/machines4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
